export default [{
        path: "/",
        name: "dashboard",
        meta: { authRequired: true },
        component: () =>
            import ("./views/dashboards/default"),
    },
    {
        path: "/login",
        name: "login",
        component: () =>
            import ("./views/account/login"),
        meta: { authRequired: false },
    },
    {
        path: "/register",
        name: "Register",
        component: () =>
            import ("./views/account/register"),
        meta: { authRequired: false },
    },
    {
        path: "/forgot-password",
        name: "Forgot password",
        component: () =>
            import ("./views/account/forgot-password"),
        meta: {
            authRequired: false
        },
    },
    {
        path: "/logout",
        name: "logout",
        component: () =>
            import ("./views/account/logout"),
        meta: {
            authRequired: true,
        },
    },


    /*######################## entity ########################*/

    /************* USERS **************/
    {
        path: "/entity/users",
        name: "entity_users",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/entity/users/index"),
    },
    {
        path: "/entity/users/:id",
        name: "entity_users_show",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/entity/users/show"),
    },



    /************* DIRECTIONS **************/
    {
        path: "/entity/directions",
        name: "entity_directions",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/entity/directions/index"),
    },
    {
        path: "/entity/directions/:id",
        name: "entity_directions_show",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/entity/directions/show"),
    },


    /************* IF-CTAFS **************/
    {
        path: "/entity/if-ctafs",
        name: "entity_if_ctafs",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/entity/if_ctafs/index"),
    },
    {
        path: "/entity/if-ctafs/:id",
        name: "entity_if_ctafs_show",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/entity/if_ctafs/show"),
    },


    /************* FORESTS **************/
    {
        path: "/entity/forests",
        name: "entity_forests",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/entity/forests/index"),
    },
    {
        path: "/entity/forests/:id",
        name: "entity_forests_show",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/entity/forests/show"),
    },


    /************* CONVENTIONS **************/
    {
        path: "/entity/conventions",
        name: "entity_conventions",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/entity/conventions/index"),
    },
    {
        path: "/entity/conventions/:id",
        name: "entity_conventions_show",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/entity/conventions/show"),
    },


    /************* INFORMATIONS **************/
    {
        path: "/entity/informations/pta",
        name: "entity_informations_pta",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/entity/informations/pta"),
    },
    {
        path: "/entity/informations/report",
        name: "entity_informations_report",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/entity/informations/report"),
    },
    {
        path: "/entity/informations/development_plan",
        name: "entity_informations_development_plan",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/entity/informations/development_plan"),
    },
    {
        path: "/entity/informations/photo",
        name: "entity_informations_photo",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/entity/informations/photo"),
    },
    {
        path: "/entity/informations/card",
        name: "entity_informations_card",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/entity/informations/card"),
    },


    /*######################## CTAF ########################*/

    /************* FORESTS **************/
    {
        path: "/ctaf/forests",
        name: "ctaf_forests",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/ctaf/forests/index"),
    },
    {
        path: "/ctaf/forests/:id",
        name: "ctaf_forests_show",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/ctaf/forests/show"),
    },

    /************* CONVENTIONS **************/
    {
        path: "/ctaf/conventions",
        name: "ctaf_conventions",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/ctaf/conventions/index"),
    },
    {
        path: "/ctaf/conventions/:id",
        name: "ctaf_conventions_show",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/ctaf/conventions/show"),
    },

    /************* INFORMATIONS **************/
    {
        path: "/ctaf/informations/pta",
        name: "ctaf_informations_pta",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/ctaf/informations/pta"),
    },
    {
        path: "/ctaf/informations/report",
        name: "ctaf_informations_report",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/ctaf/informations/report"),
    },
    {
        path: "/ctaf/informations/development_plan",
        name: "ctaf_informations_development_plan",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/ctaf/informations/development_plan"),
    },
    {
        path: "/ctaf/informations/photo",
        name: "ctaf_informations_photo",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/ctaf/informations/photo"),
    },
    {
        path: "/ctaf/informations/card",
        name: "ctaf_informations_card",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/ctaf/informations/card"),
    },




    /*######################## IF ########################*/

    /************* CTAFS **************/
    {
        path: "/if/ctafs",
        name: "if_ctafs",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/if/ctafs/index"),
    },

    /************* FORESTS **************/
    {
        path: "/if/forests",
        name: "if_forests",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/if/forests/index"),
    },
    {
        path: "/if/forests/:id",
        name: "if_forests_show",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/if/forests/show"),
    },
    /************* FORESTS **************/
    {
        path: "/if/conventions",
        name: "if_conventions",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/if/conventions/index"),
    },
    {
        path: "/if/conventions/:id",
        name: "if_conventions_show",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/if/conventions/show"),
    },

    /************* INFORMATIONS **************/
    {
        path: "/if/informations/pta",
        name: "if_informations_pta",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/if/informations/pta"),
    },
    {
        path: "/if/informations/report",
        name: "if_informations_report",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/if/informations/report"),
    },
    {
        path: "/if/informations/development_plan",
        name: "if_informations_development_plan",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/if/informations/development_plan"),
    },
    {
        path: "/if/informations/photo",
        name: "if_informations_photo",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/if/informations/photo"),
    },
    {
        path: "/if/informations/card",
        name: "if_informations_card",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/if/informations/card"),
    },





    /********* GENERAL ****** */
    {
        path: "/notifications",
        name: "notifications",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/general/notifications"),
    },

    {
        path: "/profile",
        name: "profile",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/general/profile"),
    },

    {
        path: "/setting",
        name: "setting",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/general/setting"),
    },



    // Redirect any unmatched routes to the 404 page. This may
    // require some server configuration to work in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    // {
    //     path: "*",
    //     redirect: "404",
    // },
    {
        path: "/pages/maintenance",
        name: "Maintenance",
        meta: { authRequired: true },
        component: () =>
            import ("./views/utility/maintenance"),
    },
    {
        path: "/pages/coming-soon",
        name: "coming-soon",
        meta: { authRequired: true },
        component: () =>
            import ("./views/utility/coming-soon"),
    },
    {
        path: "/pages/404",
        name: "Error-404",
        meta: { authRequired: true },
        component: () =>
            import ("./views/utility/404"),
    },
    {
        path: "/pages/500",
        name: "Error-500",
        meta: { authRequired: true },
        component: () =>
            import ("./views/utility/500"),
    },
];